/*Titre h1 custom*/
#title{
    font-weight: 600;
    text-shadow: 1px 1px 1px #2b2d42;
    .span{
        color: #d90429;
    }
    @media screen and (max-width:500px) {
       h1{
        font-size: 17px;
       }
       }
       @media screen and (max-width:400px) {
        h1{
         font-size: 13px;
        }
        }
        @media screen and (max-width:300px) {
            h1{
             font-size: 11px;
            }
            }
}
/*Button email custom*/
.mail-btn{
        background-color: #d90429;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 2px 2px 2px;
        border: none;
        img{
            width: 30px  !important;
            height: 30px !important ;
            @media screen and (max-width:500px) {
                height: 20px !important ;
                width: 20px  !important;
                border-radius: 10px;
            }
        }
}
/*Button whatsapp custom*/
.btn-wth{
    background-color: #99d98c;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 2px 2px 2px;
    transition: 0.3s;
    img{
        width: 30px;
        height: 30px;
        @media screen and (max-width:500px) {
            height: 20px !important ;
            width: 20px  !important;
        }
    }
    border: none;
}
/*Button whatsapp custom hover*/
.btn-wth:hover{
    background-color: #ffffff;
}
/*Button email custom hover*/
.mail-btn:hover{
    background-color: #9b404e;
}
.navbar{
    z-index: 1000;
    background-color: #bc4749;
    .link{
        text-align: center;
        color: #ffffff;
    }
    .link:hover{
        text-decoration: 2px underline #2b2d42;
    }
}
/* Réinitialisation globale pour éviter les marges par défaut */
body, html {
    width: 100%;
    overflow-x: hidden; /* Évite les débordements horizontaux */
}
#nav-dropdown.dropdown-toggle.nav-link{
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    .link{
        color: #2b2d42;
        text-decoration: none;
    }
}
a.link.dropdown-item{
    color: #2b2d42;
    text-decoration: none;       
}

.dropdown-item:active{
    background-color: #f7f7f7;
    color: #2b2d42;
}