   @media screen and (min-width:601px){
    .form{
       margin:20px;
       margin-left: 20%;
       width:60%;

    }
   }
   @media screen and (min-width:901px){
    .form{
       margin:20px;
       margin-left: 25%;
       width:50%;

    }
   }
   @media screen and (min-width:1000px){
    .form{
       margin:20px;
       margin-left: 30%;
       width:37%;

    }
   }
   @media screen and (max-width:600px){
    .form{
       margin-left: 1%;
       width:90%;
       margin:20px
    }
   }
.form{
    border: 1px;
    box-shadow: 3px 3px 3px #eee;
    padding: 10px;
    border-radius: 10px;
    input{
        background-color: #ffff;
        border: 1px solid #eee;
        padding: 15px;
    }

}
#submit{
    width: 30%;
    background-color: #1d3557;
}