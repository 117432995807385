.accordion-item{
    font-weight: 600;
    font-size: 15px;
    color: #2b2d42;
    background-color:#fff ;
}
.accordion-button:not(.collapsed){
    background-color: #fff;
}
button.accordion-button.collapsed{
    background-color: #f8f9fa;
}
button.accordion-button.collapsed::after{
    border: none;
}
button.accordion-button.collapsed::before{
    border: none;
}
.courses-card{
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
    span{
        text-decoration: underline #e63946 1px;
        color: #fff;
    }
}
.container{
    width: 100%;
    img{
        width: 100%;
    }
}
.course-card{
    background-color: #e63946;

}
#link-btn{
    margin: 3px;
    font-size: 16px;
    border: none;
    transition: 0.3s;
    box-shadow: 2px 2px 2px #f8f9fa;
    background-color: #6e0a19;
    @media screen and (max-width:1000px) {
        font-size: 15px;
    }
}
#link-btn:hover{
    background-color: #6e0a19d5;
}