.services-card h1{
    margin: 6px;
    text-align: center;
}
.services-card{
    align-items: center;
    display: flex;
    justify-content: center;
    span{
        text-decoration: underline #e63946 1px;
        color: #fff;
    }
}

.text-muted{
    transition: 0.3s;
    font-size: 18px;
    cursor: pointer;
}
