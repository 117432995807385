.p-4 {
    padding: 1.5rem !important;
}

.justify-content-center {
    justify-content: center !important;
}
.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.d-flex {
    display: flex !important
;
}
.p-4 {
    padding: 1.5rem !important;
}
.justify-content-center {
    justify-content: center !important;
}
.border-bottom {
    border-bottom: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}
.d-flex {
    display: flex !important
;
}
*, ::after, ::before {
    box-sizing: border-box;
}
*, *::before, *::after {
    box-sizing: border-box;
}
feuille de style de l’agent utilisateur
section {
    display: block;
    unicode-bidi: isolate;
}
.text-muted {
    transition: 0.3s;
    font-size: 18px;
    cursor: pointer;
}
.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
}
.text-center {
    text-align: center !important;
}
.bg-light {
    --mdb--bg-opacity: 1;
    background-color: rgba(251, 251, 251, var(--mdb--bg-opacity));
}
.bg-light {
    --mdb-bg-opacity: 1;
    background-color: rgba(var(--mdb-light-rgb), var(--mdb-bg-opacity)) !important;
}
.text-muted {
    --mdb-text-opacity: 1;
    color: #757575 !important;
}
.text-center {
    text-align: center !important;
}
.bloc{
    align-items: center;
    display: flex;
    img{
        width: 20%;
        margin: 3px;
        padding: 2px;
    }
}
.link-social{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 20%;
        margin: 3px;
        padding: 2px;
        @media screen  and (max-width:770px) {
            width: 5%;
        }
        @media screen  and (max-width:500px) {
            width: 8%;
        }
    }
}
.social-media-link{
    display: flex;
    align-items: center;
    p{
        margin: 8px;
    }
}