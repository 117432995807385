.card{
   justify-content: center;
   align-items: center;
   flex-direction: column;
    display: flex;
    border: none;
    background-color: #ffffff;
    p{
        font-weight: 600;
        color: #2b2d42;
    }
    li{
        color: #2b2d42;
        font-weight: 400;
    }
    .card-header{
        color: #2b2d42;
        background-color: #edf2f4;
        font-weight: 600;
    }
}
.card-group{
    margin-left: 10%;
    width: 80%;
    @media screen and (max-width:1000px) {
        width: 98%;
        margin-left: 1%;
    }
    @media screen and (max-width:800px) {
        width: 70%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15%;
    }
    @media screen and (max-width:600px) {
        width: 90%;
        margin-left: 6%;
    }
}
.card-native{
    padding: 10px;
    margin: 10px;
    box-shadow: 4px 4px 4px #f0f0f0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.card-title{
    padding: 8px;
    text-decoration: underline 1px  #fb6f92;
    color: #1b1d3b;
}