.carousel-2{
    background-image: url('../images/p11.jpg');
    background-size: cover;
    height: 65vh;
    background-repeat: no-repeat;
    @media screen and (max-width:700px) {
        height: 52vh !important;
    }
}

.carousel-child-2{
    background-color: rgba(61, 61, 61, 0.603);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    h1{
        color: #fff;
        text-align: center;
        font-size: 50px;
        @media screen and (max-width:900px) {
            font-size: 40px;
            padding: 5px;
        }
        @media screen and (max-width:730px) {
            font-size: 30px;
            padding: 5px;
        }
        @media screen and (max-width:600px) {
            font-size: 21px;
            padding: 5px;
        }
    }
}
.carousel-child-2{
    .container{
        width: 100%;                
        .search{
            padding: 10px;
            width: 65%;
            border: none;
            margin: 8px;
            @media screen and (max-width:900px) {
                width: 95%;
                padding: 15px;
            }
        }

        .col{
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:900px) {
                flex-direction: column;               
            }
        }
        .s-btn{
            padding: 10px;
            background-color: #bc4749;
            border: none;
            transition: 0.3s;
            @media screen and (max-width:900px) {
                width: 45%;
                padding: 10px;
                font-size: 18px;                
            }
            @media screen and (max-width:400px) {
                width: 70%;
                padding: 10px;
                font-size: 18px;                
            }
        }
        .s-btn:hover{
            background-color: crimson;
        }

    }
}
.items{
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-basis: 200;
    flex-grow: 1;
    margin: 20px;
    margin-top: -20vh;
    align-items: center;
    justify-content: center;
    .items-child{
        background-color: #fff;
        box-shadow: 0 0 0 5px  #fafafa;
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        margin: 10px;
        cursor: pointer;
        font-size: 30px;
        p{
            font-size: 20px;
            color: #4e4e4e;
            @media screen and (max-width:700px) {
                font-size: 18px;
            }
            @media screen and (max-width:350px) {
                font-size: 16px;
            }
        }
        transition: 0.3s;
    }
    .items-child:hover{
        padding: 20px;
        background-color: crimson;
        box-shadow: 0 0 0 0;
        p{
            color: #0f0522;
        }
    }
    @media screen and (max-width:900px) {
        margin-top:-12vh;
    }
    @media screen and (max-width:600px) {
        margin-top:-8vh;
    }
}
